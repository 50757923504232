import * as React from "react"
import { Link as TLink } from "theme-ui"
import { Link } from "gatsby"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import { deburr } from "lodash"
import removeApostrophes from "../utils/removeApostrophes";

type TagsProps = {
  tags: {
    name: string
    slug: string
  }[]
}

const ItemTags = ({ tags }: TagsProps) => {
  const { tagsPath, basePath } = useMinimalBlogConfig()

  return (
    <React.Fragment>
      {tags.map((tag, i) =>
          (
              <React.Fragment key={tag.slug}>
                {!!i && `, `}
                <TLink as={Link}
                       to={replaceSlashes(removeApostrophes(`/${basePath}/${tagsPath}/${deburr(tag.slug)}`))}>
                  {tag.name}
                </TLink>
              </React.Fragment>
          ))}
    </React.Fragment>
  )
}

export default ItemTags
