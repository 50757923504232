import * as React from "react"
import Blog from "../../../components/blog"
import {postMapper} from "../utils/postMapper";

type Props = {
  data: {
    allContentfulPerfectPost: any
    [key: string]: string
  }
  [key: string]: any
}

export default function MinimalBlogCoreBlog({...props}: Props) {
  const {
    data: {allContentfulPerfectPost},
  } = props

  const modifiedPosts = allContentfulPerfectPost.nodes.map(postMapper);

  return <Blog posts={modifiedPosts} {...props} />
}
